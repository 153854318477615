<template>

<app-content :loading="is.loading && !is.initialised">

	<app-content-head title="Reports" subtitle="Manage the reports received for games after being checked out." :readonly="$authorised('con/checkout/access', 'read')">

		<app-content-head-actions-item text="Create" icon="create" v-on:click="onCreateClick" />
		<app-content-head-actions-item text="Delete" icon="delete" :danger="true" :disabled="!selected.length" v-on:click="onDeleteClick" />

	</app-content-head>

	<app-content-body :loading="is.loading && is.initialised" :is-empty="!collection.length" placeholder="Sorry, no reports found.">

		<app-table>

			<app-table-head :layout="layout">

				<app-table-column />
				<app-table-column text="Game" />
				<app-table-column text="Shelf" />
				<app-table-column text="Date" />
				<app-table-column />

			</app-table-head>

			<app-table-body>

				<app-table-row v-on:click="onEditClick(item.id)" :success="item.winner" :layout="layout" v-for="item in collection" :key="item.id">

					<app-table-cell-label :text="$constants.checkout.report.statusName[item.status]" :type="$constants.checkout.report.statusTheme[item.status]" />
					<app-table-cell-text :text="item.game.name" :subtext="item.game.reference" />
					<app-table-cell-text :text="item.game.shelf" />
					<app-table-cell-text :text="item.date | localDate('MM/DD/YYYY h:mma')" />

					<app-table-cell-select :active="isSelected(item.id)" v-on:click="onSelectToggle(item.id)" />

				</app-table-row>

			</app-table-body>

			<app-table-foot :pagination="pagination" :total="total" />

		</app-table>

	</app-content-body>

</app-content>

</template>

<script>

import mixCollection from '@/mixin/collection'

export default {

	mixins: [mixCollection],

	data: function() {

		return {
			layout: '80px auto 80px 240px 24px',
			endpoint: 'convention/checkout/reports',
			live: 'convention/checkout/reports',
			edit: 'Convention.Checkout.Reports.Edit'
		}

	}

}

</script>

<style scoped>

</style>